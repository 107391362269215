import { create } from 'zustand';

import {
  type LockScreenStore,
  createLockScreenSlices,
} from '@/contexts/StoreContext/slices/lock-screen.slices';
import {
  createOrderPermissionSlice,
  type OrderPermissionStore,
} from '@/contexts/StoreContext/slices/order-permission.slices';
import {
  type PickUpStoreState,
  createPickUpSlice,
} from '@/contexts/StoreContext/slices/pick-up.slices';

// =================================================================

export type BoundStore = PickUpStoreState & LockScreenStore & OrderPermissionStore;

// =================================================================

export const createBoundStore = create<BoundStore>()((...a) => ({
  ...createPickUpSlice(...a),
  ...createLockScreenSlices(...a),
  ...createOrderPermissionSlice(...a),
}));
