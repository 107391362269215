import { useStore } from '@/contexts/StoreContext';
import type { MenuListItem } from '@/contexts/StoreContext/slices/order-permission.slices';

import { OrderListTable } from '@/pages/order-permission/OrderListTable';
import { SelectedOrderDetails } from '@/pages/order-permission/SelectedOrderDetails';

export const OrdersList = (props: { defaultOrderList: MenuListItem[] }) => {
  const { defaultOrderList } = props;

  const {
    selectedOrderId,
    selectedMenuList,
    setMenuList,
    setSelectedOrderId,
    setSelectedMenuList,
  } = useStore(state => state.orderPermission);

  const handleOnClose = () => {
    setMenuList(defaultOrderList);
    setSelectedOrderId(null);
    setSelectedMenuList([]);
  };

  return (
    <div className="max-h-[85vh] overflow-y-auto overflow-x-hidden border-l border-gray-500 pl-6">
      <h3 className="mb-2 text-2xl font-semibold">Выбрать заказ</h3>
      {!selectedOrderId ? (
        <OrderListTable handleClickRow={setSelectedOrderId} />
      ) : (
        <SelectedOrderDetails
          orderId={selectedOrderId}
          menuList={selectedMenuList}
          onClose={handleOnClose}
        />
      )}
    </div>
  );
};
