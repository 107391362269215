import { memo, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { LockScreen } from '@/layout/LockScreen';

import {
  LOCK_SCREEN_DEFAULT_KEY,
  LOCK_SCREEN_DEFAULT_PASSWORD,
} from '@/constants/common.constants';

import {
  TablePageLinks,
  CashPageLinks,
  MenuPageLinks,
  OrderPageLinks,
  ExpensePageLinks,
} from '@/contexts/RouterContext/router.links';
import { useStore } from '@/contexts/StoreContext';

import { Button } from '@/components/Button';
import { Logo } from '@/components/Logo';
import { NavLink } from '@/components/NavLink';

import { ReactComponent as CashSVG } from '@/assets/icons/cash.svg';
import { ReactComponent as ClocheSVG } from '@/assets/icons/cloche.svg';
import { ReactComponent as DocumentCheckmarkSVG } from '@/assets/icons/document-checkmark.svg';
import { ReactComponent as LockSVG } from '@/assets/icons/lock.svg';
import { ReactComponent as MenuSVG } from '@/assets/icons/menu.svg';
import { ReactComponent as TableSVG } from '@/assets/icons/table.svg';
import { ReactComponent as UsersSVG } from '@/assets/icons/users.svg';

// =================================================================

const menuConfig = [
  {
    title: 'Заказы',
    icon: <ClocheSVG />,
    href: OrderPageLinks.main + '?page=1&status=new',
  },
  {
    title: 'Касса',
    icon: <CashSVG />,
    href: CashPageLinks.main,
  },
  {
    title: 'Столы',
    icon: <TableSVG />,
    href: TablePageLinks.main,
  },

  {
    title: 'Меню',
    icon: <MenuSVG />,
    href: MenuPageLinks.main,
  },

  {
    title: 'Приход и расход',
    icon: <DocumentCheckmarkSVG />,
    href: ExpensePageLinks.main,
  },
];

// =================================================================

export const Sidebar = memo(() => {
  const navigate = useNavigate();

  const { isLocked, setLockScreenState } = useStore(store => store.lockScreen);

  useEffectOnce(() => {
    const tempPassword = window.localStorage.getItem(LOCK_SCREEN_DEFAULT_PASSWORD);
    if (!tempPassword) {
      window.localStorage.setItem(LOCK_SCREEN_DEFAULT_PASSWORD, '1234');
    }

    const persistedPassword = window.localStorage.getItem(LOCK_SCREEN_DEFAULT_KEY);
    console.log({ tempPassword, persistedPassword });
    if (persistedPassword === tempPassword) setLockScreenState(true);
  });

  const handleClickLockScreen = () => {
    const tempPassword = window.localStorage.getItem(LOCK_SCREEN_DEFAULT_PASSWORD);

    setLockScreenState(true);
    window.localStorage.setItem(LOCK_SCREEN_DEFAULT_KEY, tempPassword as string);
  };

  return (
    <Fragment>
      <aside className="fixed left-0 top-0 z-20 flex h-screen w-20 flex-col justify-between border-r-2 border-gray bg-white pb-20">
        <div>
          <Logo />
          <ul>
            {menuConfig.map(menu => (
              <li key={menu.href}>
                <NavLink
                  to={menu.href}
                  className="flex flex-col items-center px-1.5 py-2 text-black-100"
                >
                  {menu.icon}
                  <span className="text-center text-sm">{menu.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <div className="tooltip tooltip-right tooltip-primary mb-4 ml-2.5" data-tip="Мой профиль">
            <Button
              title="Мой профиль"
              onClick={() => navigate('/profile')}
              className="flex h-14 w-14 items-center justify-center p-0"
            >
              <UsersSVG />
            </Button>
          </div>
          <div className="tooltip tooltip-right tooltip-primary mb-4 ml-2.5" data-tip="Lock screen">
            <button
              onClick={handleClickLockScreen}
              className="flex h-14 w-14 items-center justify-center rounded-md border border-primary p-0"
            >
              <LockSVG width={36} height={36} />
            </button>
          </div>
        </div>
      </aside>
      {isLocked && <LockScreen />}
    </Fragment>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Sidebar.displayName = 'Sidebar';
}
