import type {
  DailyStatistics,
  OrderStatistics,
  TotalBalanceByCategoryList,
  TotalBalanceList,
} from '@/api/types/statistics.types';
import { httpClient } from '@/services/http-client';

export const StatisticsApi = {
  dailyStatistics: async (params?: { date: string }) => {
    const { data } = await httpClient.get<DailyStatistics>('/cashier/branch/payment_statistics', {
      params,
    });

    return data;
  },

  // ----------------------------------------------------------------

  orderStatistics: async (params: Partial<{ from: string; to: string; period: string }> = {}) => {
    const { data } = await httpClient.get<OrderStatistics>('/cashier/order/statistics', {
      params,
    });

    return data;
  },

  // ----------------------------------------------------------------

  totalBalanceByCategory: async () => {
    const { data } = await httpClient.get<TotalBalanceByCategoryList[]>(
      '/cashier/branch-balance-history/statistics/by/category',
    );

    return data;
  },

  // ----------------------------------------------------------------

  totalBalance: async () => {
    const { data } = await httpClient.get<TotalBalanceList[]>(
      '/cashier/branch-balance-history/statistics',
    );

    return data;
  },
};
