import { useQueryClient } from '@tanstack/react-query';
import { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { OrderApi } from '@/api/domains/order.api';
import type { OrderDetails } from '@/api/types/order.types';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import type { MenuList } from '@/pages/order-details/useOrderDetails';

import { useNotification } from '@/contexts/NotificationContext';
import { OrderQueryKeys } from '@/contexts/QueryContext/query.keys';
import { OrderPageLinks } from '@/contexts/RouterContext/router.links';

import { Button } from '@/components/Button';
import { OrderDetailsCard } from '@/components/OrderDetailsCard';
import { PageTitle } from '@/components/PageTitle';

import { OrderEditModal } from '@/pages/order-details/OrderEditModal';
import { PickUpConfirmCard } from '@/pages/pick-up/PickUpConfirmCard';

// =================================================================

interface OrderDetailsInfoPanelProps {
  orderDetails: OrderDetails;
  menuList: MenuList[];
  decreaseMenuItem: (itemId: number) => void;
  increaseMenuItem: (menu: MenuList) => void;
}

// =================================================================

export const OrderDetailsInfoPanel = (props: OrderDetailsInfoPanelProps) => {
  const { orderDetails, menuList, decreaseMenuItem, increaseMenuItem } = props;

  const { notification } = useNotification();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isOpenOrderInfoModal, setIsOpenOrderInfoModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await OrderApi.updateOrder({
        orderId: orderDetails.id,
        menus: menuList.map(menu => ({ id: menu.id, count: menu.count })),
      });
      await queryClient.invalidateQueries({
        queryKey: OrderQueryKeys.orderDetails(orderDetails.id),
      });
      navigate('/orders?page=1&status=new');
      notification({ type: 'success', message: 'Еда успешно добавлена!' });
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      notification({ type: 'error', message: error.response.statusText });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <PageTitle title={`Заказ № ${orderDetails.number}`} className="mb-4" />
      <OrderDetailsCard.Price
        leftText="ФИО официанта"
        rightText={orderDetails.waiter.name}
        className="mb-4"
      />
      <OrderDetailsCard.Price
        leftText="Количество гостей"
        rightText={`${orderDetails.client_count} человека`}
        className="mb-6"
      />
      <table className="mb-4 w-full">
        <thead>
          <tr>
            <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">№</td>
            <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">Название</td>
            <td className="border border-black-100 px-1.5 py-1 text-center text-sm font-semibold">
              К-во
            </td>
            <td className="border border-black-100 px-1.5 py-1 text-right text-sm font-semibold">
              Цена
            </td>
          </tr>
        </thead>
        <tbody>
          {orderDetails.menus.map((menu, index) => (
            <tr key={menu.id} className="text-sm">
              <td className="border border-black-100 px-1.5 py-1">{index + 1}</td>
              <td className="max-w-36 border border-black-100 px-1.5 py-1">{menu.name}</td>
              <td className="border border-black-100 px-1.5 py-1 text-center">{menu.count}</td>
              <td className="border border-black-100 px-1.5 py-1 text-right">
                {thousandSeperator(menu.price)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mb-4 flex items-center justify-between text-xl font-semibold">
        <p>Общая цена</p>
        <p>{thousandSeperator(orderDetails.price)} сум</p>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Button
          onClick={() => setIsOpenOrderInfoModal(true)}
          variant="primary"
          className="h-auto w-full py-3.5 text-white"
        >
          Отказ блюда
        </Button>
        <Button
          variant="warning"
          onClick={() => {
            navigate(OrderPageLinks.permission(orderDetails.id) + '?status=new');
          }}
          className="h-auto py-3.5"
        >
          Пермищения блюда
        </Button>
      </div>
      {menuList.length > 0 && (
        <Fragment>
          <ul className="mt-4 border-t border-black-100 pb-20 pt-4">
            {menuList.map(menu => (
              <PickUpConfirmCard
                key={menu.id}
                title={menu.name}
                count={menu.count}
                price={menu.price}
                handleIncreament={() => increaseMenuItem(menu)}
                handleDecreament={() => decreaseMenuItem(menu.id)}
                className="mb-3"
              />
            ))}
          </ul>
          <div className="absolute bottom-0 left-1 z-10 w-[652px] rounded-b-xl bg-white p-4">
            <Button
              variant="success"
              onClick={handleSubmit}
              disabled={isLoading}
              className="h-auto w-full py-3.5 text-white"
            >
              Добавить блюда
            </Button>
          </div>
        </Fragment>
      )}
      <OrderEditModal
        orderDetails={orderDetails}
        isOpen={isOpenOrderInfoModal}
        onClose={() => setIsOpenOrderInfoModal(false)}
      />
    </Fragment>
  );
};
