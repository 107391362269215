import { type ReactNode } from 'react';
import { NumericFormat, type NumericFormatProps } from 'react-number-format';

import { twMerge } from 'tailwind-merge';

// ----------------------------------------------------------------

type NumberInputProps = {
  name: string;
  label?: ReactNode;
  labelClassName?: string;
  wrapperClassName?: string;
  className?: string;
} & NumericFormatProps;

// ----------------------------------------------------------------

export const NumberInput = (props: NumberInputProps) => {
  const { name, label, labelClassName, wrapperClassName, className, ...rest } = props;

  return (
    <div className={twMerge('from-control mb-3', wrapperClassName)}>
      {label && (
        <label
          htmlFor={name}
          className={twMerge('label flex justify-start font-medium text-gray-500', labelClassName)}
        >
          {label}
        </label>
      )}
      <NumericFormat
        id={name}
        name={name}
        className={twMerge(
          'input input-bordered w-full border-gray-200 bg-gray-300 focus-within:outline-none focus:border-gray-400 focus:outline-none focus:ring-0',
          className,
        )}
        {...rest}
      />
    </div>
  );
};
