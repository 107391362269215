import { Fragment } from 'react';

import { twMerge } from 'tailwind-merge';

import { TransactionEnum } from '@/enums/expenses.enum';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useTotalBalanceStatistics } from '@/pages/cash/TotalBalanceStatistics/useTotalBalanceStatistics';

import { PageTitle } from '@/components/PageTitle';

export const TotalBalanceStatistics = () => {
  const { statistics, isLoading } = useTotalBalanceStatistics();

  if (isLoading || !statistics) return <h3>Loading...</h3>;

  return (
    <Fragment>
      <PageTitle title="Общий баланс" className="mb-4" />
      <div className="mb-8 overflow-hidden rounded-lg border bg-white">
        <table className="table table-md w-full whitespace-nowrap">
          <thead className="bg-gray">
            <tr className="text-base">
              <th>Название</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            {statistics.map((item, index) => (
              <tr
                key={item.tr_type}
                className={twMerge(
                  index !== statistics.length - 1 && 'border-none',
                  index % 2 === 0 && 'bg-gray-100',
                )}
              >
                <td className="text-base">
                  {item.tr_type === TransactionEnum.IN ? 'Доход' : 'Расход'}
                </td>
                <td
                  className={twMerge(
                    'text-base font-medium',
                    item.tr_type === TransactionEnum.IN && 'text-green-600',
                    item.tr_type === TransactionEnum.OUT && 'text-red-600',
                  )}
                >
                  {item.tr_type === TransactionEnum.OUT ? '-' : ''}
                  {thousandSeperator(item.amount)} сум
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
