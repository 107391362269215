import type { PaginationParams, ResponseWithPagination } from '@/api/types/common.types';
import type { OrderList, OrderDetails } from '@/api/types/order.types';
import { OrderStatusEnum, PaymentTypeEnum } from '@/enums/order.enum';
import { httpClient } from '@/services/http-client';

export const OrderApi = {
  orderList: async (
    params: PaginationParams & {
      search?: string | null;
      statuses?: OrderStatusEnum;
      check_printed?: boolean;
    },
  ) => {
    const { data } = await httpClient.get<ResponseWithPagination<OrderList>>(
      '/cashier/order/list',
      { params },
    );

    return data;
  },

  // =================================================================

  orderDetails: async (id: number | string) => {
    const { data } = await httpClient.get<OrderDetails>(`/cashier/order/${id}`);

    return data;
  },

  // =================================================================

  orderPayment: async (params: {
    id: string;
    payments: { price: number; payment_type: PaymentTypeEnum }[];
  }) => {
    const { id, payments } = params;

    return await httpClient.put(`/cashier/order/payment/${id}`, {
      payments,
    });
  },

  // =================================================================

  orderPrintOut: async (id: number) => {
    return await httpClient.get(`/cashier/order/print/check/${id}`);
  },

  // =================================================================

  cancelOrder: async (params: {
    menus: { order_menu_id: number; count: number }[];
    id: number;
  }) => {
    const { id, menus } = params;

    return await httpClient.patch('/cashier/order/menu/status', {
      status: 'CANCELLED',
      menus,
      order_id: id,
    });
  },

  // =================================================================

  updateOrder: async (params: { menus: { id: number; count: number }[]; orderId: number }) => {
    const { orderId, menus } = params;

    return await httpClient.patch(`/cashier/order/pick-up/${orderId}`, {
      menus,
    });
  },

  // =================================================================

  orderPermission: async (body: {
    from_order_id: number;
    to_order_id: number;
    menus: { order_menu_id: number; count: number }[];
  }) => {
    return await httpClient.post('/cashier/order/menu/transaction/create', body);
  },
};
