import { ColumnDef } from '@tanstack/react-table';
import { Fragment, memo, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import dayjs from 'dayjs';

import type { OrderList } from '@/api/types/order.types';
import { OrderStatusEnum } from '@/enums/order.enum';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useOrderStatistics } from '@/pages/cash/OrderStatistics/useOrderStatistics';
import { useOrderList } from '@/pages/orders/useOrderList';
import { usePrinterOrderStatistics } from '@/pages/orders/usePrintedOrderStatistics';

import { Table } from '@/components/Table';

import { OrderCancelButton } from '@/pages/orders/OrderCancelButton';
import { OrderPrintoutButton } from '@/pages/orders/OrderPrintoutButton';
import { OrderSearchBar } from '@/pages/orders/OrderSearchBar';

// =================================================================

interface OrdersTableProps {
  handleSelectOrder: (orderId: number) => void;
}

// =================================================================

export const OrdersTable = memo((props: OrdersTableProps) => {
  const { handleSelectOrder } = props;

  const { orders, isLoading, isPlaceholderData, pagination, setPagination } = useOrderList();

  const [searchParams] = useSearchParams();

  const statistics = useOrderStatistics({
    endDate: dayjs().format('DD-MM-YYYY'),
    startDate: dayjs().format('DD-MM-YYYY'),
  });

  const { totalPrice } = usePrinterOrderStatistics();

  const checkPrintedQuery = searchParams.get('checkPrinted');

  const columns = useMemo(() => {
    const columns: ColumnDef<OrderList>[] = [
      {
        accessorKey: 'created_at',
        header: 'Создано время',
      },
      {
        header: '№ Заказа',
        accessorKey: 'number',
      },
      {
        header: 'Официант',
        accessorKey: 'waiter_name',
        cell: props => {
          if (props.getValue() === null) {
            return 'Касса';
          }
          return props.getValue();
        },
      },
      {
        accessorFn: row => {
          return { tableNumber: row.table_number, isPickUp: row.pick_up };
        },
        cell: props => {
          const { tableNumber } = props.getValue() as {
            tableNumber: number;
            isPickUp: boolean;
          };
          return tableNumber || 'сoбой';
        },
        header: 'Стол',
      },
      {
        // accessorKey: 'price',
        // cell: props => `${thousandSeperator(props.getValue() as number)} сум`,
        header: 'Цена',
      },
      {
        accessorKey: 'price',
        cell: props => `${thousandSeperator(props.getValue() as number)} сум`,
        header: () => {
          return (
            <div>
              <p>Общая сумма</p>
              {statistics && (
                <Fragment>
                  {searchParams.get('status') === 'new' && (
                    <p className="text-primary">
                      {thousandSeperator(statistics.new_total_price)} сум
                    </p>
                  )}
                  {searchParams.get('status') === 'paid' && (
                    <p className="text-primary">
                      {thousandSeperator(statistics.paid_total_price)} сум
                    </p>
                  )}
                  {checkPrintedQuery && (
                    <p className="text-primary">{thousandSeperator(totalPrice)} сум</p>
                  )}
                </Fragment>
              )}
            </div>
          );
        },
      },
      {
        header: 'Действие',
        accessorFn: row => {
          return { id: row.id, status: row.status, menus: row.menus };
        },
        cell: props => {
          const { id, status } = props.getValue() as { id: number; status: OrderStatusEnum };

          return (
            <div className="flex items-center gap-2">
              <OrderPrintoutButton orderId={id} />
              {status === OrderStatusEnum.NEW && <OrderCancelButton id={id} />}
            </div>
          );
        },
      },
    ];

    if (checkPrintedQuery) {
      columns.splice(1, 0, {
        accessorKey: 'updated_at',
        header: 'Печать cчёта',
      });
    }

    return columns;
  }, [checkPrintedQuery, statistics, searchParams, totalPrice]);

  if (!orders || isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <Table
      columns={columns}
      pageCount={orders.last_page + 1}
      state={{ pagination }}
      data={orders.results}
      isLoading={isLoading || isPlaceholderData}
      onPaginationChange={setPagination}
      searchBar={<OrderSearchBar />}
      onClickRow={handleSelectOrder}
    />
  );
});

if (process.env.NODE_ENV !== 'production') {
  OrdersTable.displayName = 'OrdersTable';
}
