import { Fragment } from 'react';

import { twMerge } from 'tailwind-merge';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useTotalCategoryBalance } from '@/pages/cash/TotalBalanceByCategoryStatistics/useTotalCategoryBalance';

import { PageTitle } from '@/components/PageTitle';

export const TotalBalanceByCategoryStatistics = () => {
  const { isLoading, statistics } = useTotalCategoryBalance();

  if (isLoading || !statistics) return <h3>Loading...</h3>;

  return (
    <Fragment>
      <PageTitle title="Статистика по категории" className="mb-4" />
      <div className="mb-8 overflow-hidden rounded-lg border bg-white">
        <table className="table table-md w-full whitespace-nowrap">
          <thead className="bg-gray">
            <tr className="text-base">
              <th>Название категории</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            {statistics.map((item, index) => (
              <tr
                key={item.category_id || 'total'}
                className={twMerge(
                  index !== statistics.length - 1 ? 'border-none' : 'font-bold',
                  index % 2 === 0 && 'bg-gray-100',
                )}
              >
                <td className="text-base">{item.category}</td>
                <td
                  className={twMerge(
                    'text-base font-medium',
                    item.tr_type === 'IN' && 'text-green-600',
                    item.tr_type === 'OUT' && 'text-red-600',
                    item.amount < 0 && item.tr_type === 'total' && 'text-red-600',
                    item.amount >= 0 && item.tr_type === 'total' && 'text-green-600',
                  )}
                >
                  {item.tr_type === 'OUT' ? '-' : ''}
                  {thousandSeperator(item.amount)} сум
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
