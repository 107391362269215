import { Fragment } from 'react';

import { OrderStatistics } from '@/pages/cash/OrderStatistics';
import { PaymentStatistics } from '@/pages/cash/PaymentStatistics';
import { TotalBalanceByCategoryStatistics } from '@/pages/cash/TotalBalanceByCategoryStatistics';
import { TotalBalanceStatistics } from '@/pages/cash/TotalBalanceStatistics';

export const CashPage = () => {
  return (
    <Fragment>
      <OrderStatistics />
      <PaymentStatistics />
      <TotalBalanceByCategoryStatistics />
      <TotalBalanceStatistics />
    </Fragment>
  );
};
