import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { MenuApi } from '@/api/domains/menu.api';

import { MenuQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useMenuList = () => {
  const [searchParams] = useSearchParams();

  const categoryId = searchParams.get('categoryId');
  const search = searchParams.get('menuTitle');

  const params: { search?: string; category_id?: string } = {};
  if (search) {
    params.search = search;
  }
  if (categoryId) {
    params.category_id = categoryId;
  }

  const { data: menuList, isLoading } = useQuery({
    queryKey: MenuQueryKeys.menuList(categoryId, search),
    queryFn: () => MenuApi.menuList(params),
    staleTime: Infinity,
  });

  return { menuList, isLoading };
};
