import { ColumnDef } from '@tanstack/react-table';

import type { OrderList } from '@/api/types/order.types';

import { useOrderList } from '@/pages/orders/useOrderList';

import { Table } from '@/components/Table';

import { OrderSearchBar } from '@/pages/orders/OrderSearchBar';

// =================================================================

const columns: ColumnDef<OrderList>[] = [
  {
    header: '№ Заказа',
    accessorKey: 'number',
  },
  {
    header: 'Официант',
    accessorKey: 'waiter_name',
    cell: props => {
      if (props.getValue() === null) {
        return 'Касса';
      }
      return props.getValue();
    },
  },
  {
    accessorFn: row => {
      return { tableNumber: row.table_number, isPickUp: row.pick_up };
    },
    cell: props => {
      const { tableNumber } = props.getValue() as {
        tableNumber: number;
        isPickUp: boolean;
      };
      return tableNumber || 'сoбой';
    },
    header: 'Стол',
  },
];

// =================================================================

export const OrderListTable = (props: { handleClickRow: (id: number) => void }) => {
  const { handleClickRow } = props;

  const { orders, isLoading, isPlaceholderData, pagination, setPagination } = useOrderList();

  if (!orders || isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <Table
      columns={columns}
      pageCount={orders.last_page + 1}
      state={{ pagination }}
      data={orders.results}
      isLoading={isLoading || isPlaceholderData}
      onPaginationChange={setPagination}
      searchBar={<OrderSearchBar />}
      tableCellClassName="text-lg py-5"
      onClickRow={handleClickRow}
    />
  );
};
