import { twMerge } from 'tailwind-merge';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { Button } from '@/components/Button';

import { ReactComponent as ArrowRightSVG } from '@/assets/icons/arrow-line-right.svg';

// =================================================================

interface OrderListCardProps {
  title: string;
  price: number;
  count: number;
  handleDecreament: VoidFunction;
  handleIncreament: VoidFunction;
  className?: string;
  maxCount?: number;
  disabled: boolean;
}

// =================================================================

export const OrderListCard = (props: OrderListCardProps) => {
  const {
    count,
    handleDecreament,
    handleIncreament,
    price,
    title,
    className,
    maxCount = 9999,
    disabled,
  } = props;

  return (
    <li
      className={twMerge(
        'mb-4 flex items-center gap-6 rounded-lg border border-gray-300 px-3 py-2',
        className,
      )}
    >
      <h3 className="w-2/5 text-lg font-medium">{title}</h3>
      <p>{thousandSeperator(price)} сум</p>
      <div className="ml-auto flex items-center gap-4">
        <Button
          variant={disabled ? 'outline' : 'neutral'}
          onClick={handleDecreament}
          disabled={count >= maxCount || disabled}
        >
          <ArrowRightSVG width={24} className="rotate-180" />
        </Button>
        <p className="w-7 text-center text-lg font-medium">{count}</p>
        <Button
          variant={disabled ? 'outline' : 'neutral'}
          onClick={handleIncreament}
          disabled={count === 0 || disabled}
        >
          <ArrowRightSVG width={24} />
        </Button>
      </div>
    </li>
  );
};
