import { useEffectOnce, useUnmount } from 'react-use';

import type { OrderDetails } from '@/api/types/order.types';

import { useStore } from '@/contexts/StoreContext';

import { OrderDetailsCard } from '@/components/OrderDetailsCard';

import { OrderListCard } from '@/pages/order-permission/OrderListCard';

interface OrderDetailsListProps {
  orderDetails: OrderDetails;
}

export const OrderDetailsList = (props: OrderDetailsListProps) => {
  const { orderDetails } = props;

  const {
    setMenuList,
    menuList,
    clearMenuList,
    decreaseMenuItem,
    increaseMenuItem,
    selectedOrderId,
  } = useStore(state => state.orderPermission);

  useUnmount(clearMenuList);

  useEffectOnce(() => {
    setMenuList(
      orderDetails.menus.map(menu => ({
        id: menu.id,
        title: menu.name,
        price: menu.price,
        count: menu.count,
        maxCount: menu.count,
      })),
    );
  });

  return (
    <div className="pr-6">
      <h3 className="mb-5 text-2xl font-semibold">Заказ № {orderDetails.number}</h3>
      <OrderDetailsCard.Price
        leftText={'ФИО официанта'}
        rightText={orderDetails.waiter.name || 'касса'}
        className="mb-3"
      />
      <OrderDetailsCard.Price
        leftText={'Количество гостей'}
        rightText={`${orderDetails.client_count} человека`}
        className="mb-3"
      />
      <OrderDetailsCard.Price
        leftText="Стол номер"
        rightText={orderDetails.table_number || 'собой'}
        className="mb-6"
      />
      <ul>
        {menuList.map(menu => (
          <OrderListCard
            key={menu.id}
            handleDecreament={() => decreaseMenuItem(menu.id)}
            handleIncreament={() => increaseMenuItem(menu)}
            title={menu.title}
            price={menu.price}
            count={menu.count}
            disabled={!Boolean(selectedOrderId)}
            maxCount={menu.maxCount}
          />
        ))}
      </ul>
    </div>
  );
};
