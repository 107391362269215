import { type ChangeEventHandler } from 'react';
import { useSearchParams } from 'react-router-dom';

import { debounce } from 'lodash';

import { NumberInput } from '@/components/Inputs/NumberInput';

export const OrderSearchBar = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const allParams = Object.fromEntries(searchParams);

  const handleChange: ChangeEventHandler<HTMLInputElement> = debounce(event => {
    if (event.target.value) {
      setSearchParams({
        ...allParams,
        search: event.target.value,
      });
    } else {
      if (searchParams.get('search')) {
        searchParams.delete('search');
        setSearchParams(searchParams);
      }
    }
  }, 200);

  return (
    <NumberInput
      name="search"
      label="Поиск по заказу или столу номер"
      placeholder="Поиск..."
      onChange={handleChange}
    />
  );
};
