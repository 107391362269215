import type { CreateSlice } from '@/contexts/StoreContext/store.types';

export type MenuListItem = {
  id: number;
  title: string;
  count: number;
  price: number;
  maxCount: number;
};

export type OrderPermissionState = {
  selectedOrderId: number | null;
  menuList: Array<MenuListItem>;
  selectedMenuList: Array<MenuListItem>;
  setMenuList: (list: Array<MenuListItem>) => void;
  setSelectedMenuList: (list: Array<MenuListItem>) => void;
  setSelectedOrderId: (id: number | null) => void;
  increaseMenuItem: (item: MenuListItem) => void;
  decreaseMenuItem: (itemId: number) => void;
  clearMenuList: VoidFunction;
};

export type OrderPermissionStore = {
  orderPermission: OrderPermissionState;
};

export const createOrderPermissionSlice: CreateSlice<OrderPermissionStore> = set => ({
  orderPermission: {
    selectedOrderId: null,
    menuList: [],
    selectedMenuList: [],
    setMenuList: list =>
      set(state => ({
        orderPermission: { ...state.orderPermission, menuList: list },
      })),
    setSelectedMenuList: list =>
      set(state => ({
        orderPermission: { ...state.orderPermission, selectedMenuList: list },
      })),
    setSelectedOrderId: id =>
      set(state => ({
        orderPermission: { ...state.orderPermission, selectedOrderId: id },
      })),
    increaseMenuItem: item =>
      set(state => {
        const currentMenuList = state.orderPermission.selectedMenuList;
        const existItemIndex = currentMenuList.findIndex(menu => menu.id === item.id);
        if (existItemIndex !== -1) {
          currentMenuList.splice(existItemIndex, 1, {
            ...currentMenuList[existItemIndex],
            count: currentMenuList[existItemIndex].count + 1,
          });
        } else {
          currentMenuList.push({
            ...item,
            count: 1,
          });
        }

        const selectedMenuList = state.orderPermission.menuList;
        const selectedMenuIndex = selectedMenuList.findIndex(menu => menu.id === item.id);
        selectedMenuList.splice(selectedMenuIndex, 1, {
          ...selectedMenuList[selectedMenuIndex],
          count: selectedMenuList[selectedMenuIndex].count - 1,
        });

        return {
          orderPermission: {
            ...state.orderPermission,
            selectedMenuList: currentMenuList,
            menuList: selectedMenuList,
          },
        };
      }),
    decreaseMenuItem: itemId =>
      set(state => {
        const currentMenuList = state.orderPermission.selectedMenuList;
        const existItemIndex = currentMenuList.findIndex(menu => menu.id === itemId);
        if (existItemIndex !== -1) {
          currentMenuList.splice(existItemIndex, 1, {
            ...currentMenuList[existItemIndex],
            count: currentMenuList[existItemIndex].count - 1,
          });
        }

        if (currentMenuList[existItemIndex].count === 0) {
          currentMenuList.splice(existItemIndex, 1);
        }

        const selectedMenuList = state.orderPermission.menuList;
        const selectedMenuIndex = selectedMenuList.findIndex(menu => menu.id === itemId);
        selectedMenuList.splice(selectedMenuIndex, 1, {
          ...selectedMenuList[selectedMenuIndex],
          count: selectedMenuList[selectedMenuIndex].count + 1,
        });

        return {
          orderPermission: {
            ...state.orderPermission,
            selectedMenuList: currentMenuList,
            menuList: selectedMenuList,
          },
        };
      }),
    clearMenuList: () =>
      set(state => ({
        orderPermission: {
          ...state.orderPermission,
          menuList: [],
          selectedMenuList: [],
          selectedOrderId: null,
        },
      })),
  },
});
