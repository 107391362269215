import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useOrderDetails } from '@/pages/order-details/useOrderDetails';

import { OrderPageLinks } from '@/contexts/RouterContext/router.links';

import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';

import { OrderDetailsList } from '@/pages/order-permission/OrderDetailsList';
import { OrdersList } from '@/pages/order-permission/OrdersList';

import { ReactComponent as ArrowLeftSVG } from '@/assets/icons/arrow-left.svg';

export const OrderPermissionPage = () => {
  const navigate = useNavigate();

  const params = useParams() as { id: string };

  const { order } = useOrderDetails({
    orderId: Number(params.id),
  });

  if (!order) return null;

  return (
    <Fragment>
      <div className="mb-6 flex items-center gap-4">
        <Button
          variant="neutral"
          onClick={() => navigate(OrderPageLinks.details(params.id))}
          className="h-fit min-h-fit p-3"
        >
          <ArrowLeftSVG width={24} height={24} />
        </Button>
        <PageTitle title={order.table_number ? 'Стол №' + order.table_number : 'Coбой'} />
      </div>
      <div className="grid grid-cols-[1.5fr_2fr]">
        <OrderDetailsList orderDetails={order} />
        <OrdersList
          defaultOrderList={order.menus.map(menu => ({
            id: menu.id,
            count: menu.count,
            title: menu.name,
            price: menu.price,
            maxCount: menu.count,
          }))}
        />
      </div>
    </Fragment>
  );
};
