import { useQuery } from '@tanstack/react-query';

import { StatisticsApi } from '@/api/domains/statistics.api';

import { StatisticsQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useTotalBalanceStatistics = () => {
  const { data, isLoading } = useQuery({
    queryKey: StatisticsQueryKeys.totalBalance(),
    queryFn: StatisticsApi.totalBalance,
    staleTime: 0,
    refetchInterval: 5000,
  });

  return { statistics: data, isLoading };
};
