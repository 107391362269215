import { Fragment, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { OrderApi } from '@/api/domains/order.api';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useOrderDetails } from '@/pages/order-details/useOrderDetails';

import { useNotification } from '@/contexts/NotificationContext';
import { OrderPageLinks } from '@/contexts/RouterContext/router.links';
import type { MenuListItem } from '@/contexts/StoreContext/slices/order-permission.slices';

import { Button } from '@/components/Button';
import { OrderDetailsCard } from '@/components/OrderDetailsCard';

import { ReactComponent as CloseSVG } from '@/assets/icons/close.svg';

// =================================================================

interface SelectedOrderDetailsProps {
  orderId: number;
  onClose: VoidFunction;
  menuList: MenuListItem[];
}

// =================================================================

export const SelectedOrderDetails = (props: SelectedOrderDetailsProps) => {
  const { orderId, onClose, menuList } = props;

  const { notification } = useNotification();
  const navigate = useNavigate();
  const params = useParams() as { id: string };

  const [isPending, setIsPending] = useState(false);

  const { order, isLoading } = useOrderDetails({ orderId });

  const handleSubmit = async () => {
    setIsPending(true);
    try {
      await OrderApi.orderPermission({
        from_order_id: Number(params.id),
        to_order_id: orderId,
        menus: menuList.map(menu => ({ order_menu_id: menu.id, count: menu.count })),
      });
      navigate(OrderPageLinks.main);
      notification({ type: 'success', message: 'Добавлено!' });
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      notification({ type: 'error', message: error.response.statusText });
    } finally {
      setIsPending(false);
    }
  };

  if (!order || isLoading) return <h3>Loading...</h3>;

  return (
    <Fragment>
      <div className="mb-5 flex items-center justify-between ">
        <h3 className="text-2xl font-semibold">Заказ № {order.number}</h3>
        <Button type="button" variant="neutral" onClick={onClose} className="h-auto p-3.5">
          <CloseSVG />
        </Button>
      </div>
      <OrderDetailsCard.Price
        leftText={'ФИО официанта'}
        rightText={order.waiter.name || 'касса'}
        className="mb-3"
      />
      <OrderDetailsCard.Price
        leftText={'Количество гостей'}
        rightText={`${order.client_count} человека`}
        className="mb-3"
      />
      <OrderDetailsCard.Price
        leftText="Стол номер"
        rightText={order.table_number || 'собой'}
        className="mb-6"
      />
      <table className="mb-6 w-full">
        <thead>
          <tr>
            <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">№</td>
            <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">Название</td>
            <td className="border border-black-100 px-1.5 py-1 text-center text-sm font-semibold">
              К-во
            </td>
            <td className="border border-black-100 px-1.5 py-1 text-right text-sm font-semibold">
              Цена
            </td>
          </tr>
        </thead>
        <tbody>
          {order.menus.map((menu, index) => (
            <tr key={menu.id} className="text-sm">
              <td className="border border-black-100 px-1.5 py-1">{index + 1}</td>
              <td className="max-w-36 border border-black-100 px-1.5 py-1">{menu.name}</td>
              <td className="border border-black-100 px-1.5 py-1 text-center">{menu.count}</td>
              <td className="border border-black-100 px-1.5 py-1 text-right">
                {thousandSeperator(menu.price)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {menuList.length > 0 && (
        <Fragment>
          <h3 className="mb-5 text-2xl font-semibold"> Пермищения блюда</h3>
          <table className="mb-5 w-full">
            <thead>
              <tr>
                <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">№</td>
                <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">
                  Название
                </td>
                <td className="border border-black-100 px-1.5 py-1 text-center text-sm font-semibold">
                  К-во
                </td>
                <td className="border border-black-100 px-1.5 py-1 text-right text-sm font-semibold">
                  Цена
                </td>
              </tr>
            </thead>
            <tbody>
              {menuList.map((menu, index) => (
                <tr key={menu.id} className="text-sm">
                  <td className="border border-black-100 px-1.5 py-1">{index + 1}</td>
                  <td className="max-w-36 border border-black-100 px-1.5 py-1">{menu.title}</td>
                  <td className="border border-black-100 px-1.5 py-1 text-center">{menu.count}</td>
                  <td className="border border-black-100 px-1.5 py-1 text-right">
                    {thousandSeperator(menu.price)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button
            disabled={isPending}
            onClick={handleSubmit}
            variant={isPending ? 'outline' : 'primary'}
            className="h-auto w-full p-3.5"
          >
            Добавить
          </Button>
        </Fragment>
      )}
    </Fragment>
  );
};
